import * as singleSpa from "single-spa";
import type { LifeCycles } from "single-spa";

import { Auth, UserPermissionsMap } from "@packages/service-api";
import { config, Application, applications } from "@nutrien-operations/config";
import MessageBus from "@packages/message-bus";
import { Sentry, initializeSentry } from "@packages/sentry";
const {
  addErrorHandler,
  getAppStatus,
  getMountedApps,
  LOAD_ERROR,
  navigateToUrl,
  registerApplication,
  start
} = singleSpa;

initializeSentry("orchestrator");

// configure Amplify Auth
Auth.init(config.Amplify);

// eslint-disable-next-line
console.log("config.environment", config.environment);

// flag to stop applications attempting to re-register on updates to the user profile
let hasLoaded = false;

// wait for the user profile to load before starting the application.
// This means the user is authenticated and we can render all UI
MessageBus.subscribe<UserPermissionsMap>("auth.profile", () => {
  if (hasLoaded) {
    return;
  }

  // use the userName as the id so it shows up better in Sentry
  const user = {
    id: Auth.userProfile.userName,
    username: Auth.userProfile.personId,
    email: Auth.userProfile.email,
    name: `${Auth.userProfile.firstName} ${Auth.userProfile.lastName}`
  };

  Sentry.setUser(user);

  // We need to ensure that the navigation application is loaded before any other application. So we separate it from
  // any other application so we can load it first.
  const { navigation, otherApplications } = Object.values(applications).reduce<{
    navigation: Application;
    otherApplications: Application[];
  }>(
    (result, app) => {
      if (app.name === "@nutrien-operations/ui-navigation") {
        result.navigation = app;
      } else {
        result.otherApplications.push(app);
      }

      return result;
    },
    {
      navigation: undefined,
      otherApplications: []
    }
  );

  // The navigation must exist for the app to be in a valid state. As such, we want to ensure it exists
  if (!navigation) {
    throw new Error(
      "The @nutrien-operations/ui-navigation application failed to load. This application is required and must be configured and loadable"
    );
  }

  // Handle app load error gracefully. This should only ever happen in development
  addErrorHandler((err) => {
    if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
      System.delete(System.resolve(err.appOrParcelName));

      const errorMessage = "Application is not loaded or failed to load";
      alert(errorMessage);

      // log the actual error to the console for debug purposes
      // eslint-disable-next-line
      console.error(errorMessage, err);

      navigateToUrl("/");
    }
  });

  // We subscribe to the "app.load" channel to listen for when the ui-navigation application has loaded.
  MessageBus.subscribe<Application>("app.load", (message, application) => {
    const mountedApps = getMountedApps();

    if (application.name === "@nutrien-operations/ui-navigation") {
      otherApplications
        // filter out any applications that are already mounted
        .filter((a) => !mountedApps.includes(a.name))
        .forEach((application) => {
          registerApplication(
            application.name,
            () => System.import(application.name) as Promise<LifeCycles>,
            (location) => {
              if (!application.path) {
                return true;
              }

              return application.path.exact
                ? location.pathname === application.path.value
                : location.pathname.startsWith(application.path.value);
            },
            { applicationId: application.applicationId }
          );
        });
    }
  });

  // load the ui-navigation application
  registerApplication(
    navigation.name,
    () => System.import(navigation.name) as Promise<LifeCycles>,

    (location) => {
      if (!navigation.path) {
        return true;
      }

      return navigation.path.exact
        ? location.pathname === navigation.path.value
        : location.pathname.startsWith(navigation.path.value);
    },
    { applicationId: navigation.applicationId }
  );

  start();

  hasLoaded = true;

  // remove the placeholder loading UI
  const loadingUI = document.getElementsByClassName("application-loading");

  if (loadingUI && loadingUI[0]) {
    loadingUI[0].remove();
  }
});
